import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components';

import { FaBars, FaTimes } from 'react-icons/fa'

const NavLinkItem = styled.li`
.hover-underline {
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background-color: #0DAAD6;
    bottom: -2px;
    left: 0;
    transition: width 0.3s ease;
  }

  &:hover::before {
    width: 100%;
  }
}
`;

const navLinks = [
    {
        name: 'Home',
        location: '/',
        class: 'hover-underline'
    },
    {
        name: 'Order',
        location: '/order',
        class: 'bg-primary hover:bg-sky-600 transition rounded px-4 py-2'
    }
]

export default function Navbar() {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ isMobile, setIsMobile ] = useState(window.innerWidth < 768);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    const handleResize = () => {
        setIsOpen(false);
        setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    }
}, []);

  return (
    <nav className='backdrop-blur-md shadow'>
        <div className="container py-6">
            <div className="flex items-center justify-between">
                <h4 className='font-extrabold text-xl cursor-pointer'>
                    Illusion
                </h4>

                {isMobile ? (
                    isOpen ? (
                        <FaTimes onClick={handleToggle} className='cursor-pointer' />
                    ) : (
                        <FaBars onClick={handleToggle} className='cursor-pointer' />
                    )
                ) : (
                    <ul className='flex space-x-8'>
                        {navLinks.map((item, idx) => (
                            <NavLinkItem key={idx} className='font-medium relative'>
                                <Link to={item.location} className={item.class}>
                                    {item.name}
                                </Link>
                            </NavLinkItem>
                        ))}
                    </ul>
                )}
            </div>

            {isOpen ? (
            <ul className='mt-6'>
                {navLinks.map((item, idx) => (
                    <li key={idx} className={`font-medium ${item.location === '/order' ? 'mt-3' : 'mt-1'}`}>
                        <Link to={item.location} className={item.class}>
                            {item.name}
                        </Link>
                    </li>
                ))}
            </ul>
            ) : null}
        </div>
    </nav>
  )
}
