import React from 'react'

const skills = ['Spigot Plugin Development', 'Minigame Development', 'NMS, Packets and Internals', 'Large-scale projects, Infrastructure']

export default function About() {
  return (
    <div className="container mt-44 overflow-hidden" id="about">
        <div className="grid grid-cols-1 sm:grid-cols-2 items-center gap-16">
            <div data-aos="fade-right">
                <h2 className='text-3xl font-bold'>About Me</h2>
                <p className='mt-4 text-bluishWhite'>I'm a Minecraft Server Developer with nearly a decade of experience, specializing in the creation of Spigot plugins, ranging from simple features to complex ecosystems. On my free time I write development guides, helping other beginners improve their service quality in order to further grow the industry.</p>
            </div>
            <div data-aos="fade-left">
                {skills.map((item, idx) => (
                    <div key={idx} className='bg-primary hover:bg-transparent cursor-pointer border-2 border-primary rounded px-6 py-3 mb-2 transition'>
                        {item}
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}
