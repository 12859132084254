import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";

import Hero from '../sections/Hero'
import About from '../sections/About'
import Portfolio from '../sections/Portfolio'
import Contact from '../sections/Contact'

export default function Home() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>
            <Hero />
            <About />
            <Portfolio />
            <Contact />
        </>
    );
};