import React, { useState } from 'react'
import axios from 'axios'
import { FaPaperPlane, FaDiscord, FaEnvelope } from 'react-icons/fa';

import Illustration from '../assets/contact-illustration.svg'

const socialMedia = [
    {
        icon: <FaDiscord />,
        location: 'https://discord.com/users/262706156944883712'
    },
    {
        icon: <FaEnvelope />,
        location: 'mailto:contact@illusionthe.dev'
    }
]

const inputClass = 'border-2 border-[#000c17] focus:border-primary bg-[#000c17] px-6 py-3 rounded-full w-full placeholder-bluishWhite outline-0 transition';

export default function Contact() {
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (success) return;

    if (formData.name.trim().length === 0 || formData.email.trim().length === 0 || formData.message.trim().length === 0) {
        setMessage('Please, fill all fields.');
        return;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)) {
        setMessage('Provide a valid email.');
        return;
    }

    const formDataObject = new FormData();
    formDataObject.append("type", 2);
    formDataObject.append("name", formData.name);
    formDataObject.append("email", formData.email);
    formDataObject.append("message", formData.message);

    axios.post("/discord.php", formDataObject, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    .then(response => {
        if (response.status === 201) {
            setMessage ('Your form has been sent successfully. You\'ll be contacted soon!');
            setSuccess (true);
        }
    })
    .catch(error => {
        console.log(error);
        setMessage ('Oops, something went wrong. Please, try again.');
    });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="container mt-44 overflow-hidden" id="contact">
      <h2 className="text-3xl font-bold text-center">Get In Touch</h2>

        <div className='mt-12'>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                <img src={Illustration} alt="" className='max-w-[30rem]' data-aos="fade-right" />
                
                <div data-aos="fade-left">
                    <div className="mb-6 flex space-x-4">
                        {socialMedia.map((item, idx) => (
                            <a href={item.location} target="_blank" rel='noreferrer' className='p-4 text-2xl bg-primary hover:bg-transparent border-2 border-primary rounded-full transition' key={idx}>
                                <span>{item.icon}</span>
                            </a>
                        ))}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <input type="text" placeholder="Name" name="name" className={inputClass} onChange={handleInputChange} required />
                        </div>
                        <div className="mb-4">
                            <input type="email" placeholder="Email" name="email" className={inputClass} onChange={handleInputChange} required />
                        </div>
                        <div className="mb-4">
                            <textarea name="message" placeholder="Message" className={`${inputClass} rounded-xl`} rows="5" onChange={handleInputChange} required></textarea>
                        </div>
                        
                        <p className='mb-4'>{message}</p>

                        <button className={`flex items-center justify-center py-3 px-6 rounded w-full font-semibold bg-primary hover:bg-sky-600 transition ${success ? 'hidden' : ''}`} type='submit'><span className='mr-2'>Send</span> <FaPaperPlane /></button>
                    </form>
                </div>
            </div>
        </div>
     </div>
  )
}
