import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function Layout() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
        <Navbar />
        <Outlet />
        <Footer />
    </>
  )
}
