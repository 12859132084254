import React, { useState } from 'react';

import { FaGithub } from 'react-icons/fa';

import imgPortfolio1 from '../assets/portfolio1.webp';
import imgPortfolio2 from '../assets/portfolio2.webp';
import imgPortfolio3 from '../assets/portfolio3.webp';

const portfolioItems = [
  {
    name: 'Skyblock Core',
    thumbnail: imgPortfolio1,
    description: 'A simple minimalistic, platform-agnostic and scalable Skyblock core. The project has started in Dec 2020, and has been completely remade in Jul 2023. ',
    github: 'https://github.com/IllusionTheDev/SkyblockCore/'
  },
  {
    name: 'AxolotlSMP',
    thumbnail: imgPortfolio2,
    description: 'I am one of the core developers of the upcoming AxolotlSMP. My work varies from forking the world generator, to making a custom event core which allows custom minigames to be ran every hour.',
    github: ''
  },
  {
    name: 'Freelancing',
    thumbnail: imgPortfolio3,
    description: 'I have been freelancing for the past 7 years and can\'t possibly enumerate every single project. I invite you to check my github page where you can find some old passion projects of mine.',
    github: 'https://www.github.com/IllusionTheDev?tab=repositories'
  },
];

export default function Portfolio() {
  const [portfolioItem, setPortfolioItem] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleOpenItem = (idx) => {
    setPortfolioItem(portfolioItems[idx]);
    setShowModal(true);
  };

  return (
    <>
    <div className="container mt-44 overflow-hidden" id="portfolio">
      <h2 className="text-3xl font-bold text-center">Portfolio</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-12">
        {portfolioItems.map((item, idx) => (
          <div key={idx} className="relative overflow-hidden" data-aos="zoom-in">
            <img
              src={item.thumbnail}
              alt=""
              className="m-auto transition-transform transform hover:scale-110 cursor-pointer"
              onClick={() => handleOpenItem(idx)}
            />
          </div>
        ))}
      </div>
    </div>

    {showModal && (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none m-4">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-[#00070D] outline-none focus:outline-none" data-aos="zoom-in">
              <div className="flex items-start justify-between p-5 rounded-t">
                <h3 className="text-2xl font-semibold">
                  {portfolioItem.name}
                </h3>
              </div>

              <div className="relative px-6 flex-auto">
                <div className="flex justify-center relative">
                  <img
                    src={portfolioItem.thumbnail}
                    alt=""
                    className="max-h-[350px] max-w-full"
                  />
                </div>
              </div>

              <div className="relative px-6 flex-auto">
                <p className="my-4 text-purplishWhite text-md leading-relaxed">
                  {portfolioItem.description}
                </p>
              </div>

              <div className={`flex ${portfolioItem.github === '' ? 'justify-end' : 'justify-between'} items-center p-6`}>
                {portfolioItem.github !== '' ? (
                  <a href={portfolioItem.github} target='_blank' rel='noreferrer' className='text-4xl'>
                    <FaGithub />
                  </a>
                ) : null}

                <button
                  className="text-white bg-primary hover:bg-sky-600 transition rounded px-4 py-3 font-bold uppercase text-sm mr-1 mb-1"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )}
    </>
  );
}
