import React from 'react'

import { FaGithub } from 'react-icons/fa'

export default function Footer() {
  return (
    <footer className='mt-44 pb-6'>
        <div className="container">
            <div className="flex items-center justify-between">
                <p className='text-bluishWhite text-sm'>Copyright © 2023 Illusion. All rights reserved.</p>

                <div>
                    <a href="https://github.com/IllusionTheDev" target="_blank" rel="noreferrer" className='text-3xl'>
                        <FaGithub />
                    </a>
                </div>
            </div>
        </div>
    </footer>
  )
}
