import React, { useState } from 'react'
import axios from 'axios'

const inputClass = 'border-2 border-[#000c17] focus:border-primary bg-[#000c17] px-6 py-3 rounded-full w-full placeholder-bluishWhite outline-0 transition';

export default function Order() {
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    contact_information: '',

    project_name: '',
    project_version: '',
    project_description: '',

    budget: '',
    timeframe: '',

    question1: '',
    question2: '',

    additional_information: ''
  });
  

  const handleSubmit = (e) => {
    e.preventDefault();

    if (success) return;

    if (formData.contact_information.trim().length === 0 || formData.project_name.trim().length === 0 || formData.project_version.trim().length === 0 ||
        formData.project_description.trim().length === 0 || formData.budget.trim().length === 0 || formData.timeframe.trim().length === 0) {
        setMessage('Please, fill all fields.');
        return;
    }

    const formDataObject = new FormData();
    formDataObject.append("type", 1);
    formDataObject.append("contact_information", formData.contact_information);
    formDataObject.append("project_name", formData.project_name);
    formDataObject.append("project_version", formData.project_version);
    formDataObject.append("project_description", formData.project_description);
    formDataObject.append("budget", formData.budget);
    formDataObject.append("timeframe", formData.timeframe);

    if (formData.additional_information.trim().length > 0) formDataObject.append("additional_information", formData.additional_information);
    
    if (formData.question1) formDataObject.append("question1", formData.question1);
    if (formData.question2) formDataObject.append("question2", formData.question2);

    axios.post("/discord.php", formDataObject, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    .then(response => {
        if (response.status === 201) {
            setMessage ('Your form has been sent successfully. You\'ll be contacted soon!');
            setSuccess (true);
        }
    })
    .catch(error => {
        console.log(error);
        setMessage ('Oops, something went wrong. Please, try again.');
    });
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    const newValue = type === 'checkbox' ? checked : value;
  
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  return (
    <div className="container mt-32">
        <h1 className='text-4xl font-bold text-center'>Let's work together!</h1>

        <form className='mt-20' onSubmit={handleSubmit}>
            <div className="mb-4">
                <input type="text" placeholder="Contact Information (e.g.: discord/email)" name="contact_information" className={inputClass} onChange={handleInputChange} required />
            </div>

            <h4 className="text-xl font-semibold mt-12">Project Information</h4>

            <div className="grid md:grid-cols-2 gap-5 mb-5 mt-8">
                <input type="text" placeholder="Name" name="project_name" className={inputClass} onChange={handleInputChange} required />
                <input type="text" placeholder="Server version(s)" name="project_version" className={inputClass} onChange={handleInputChange} required />
            </div>

            <div className="mb-4">
                <textarea placeholder="Description" name="project_description" className={`${inputClass} rounded-xl`} rows="3" onChange={handleInputChange} required></textarea>
            </div>

            <div className="grid md:grid-cols-2 gap-5 mb-4">
                <input type="text" placeholder="Budget" name="budget" className={inputClass} onChange={handleInputChange} required />
                <input type="text" placeholder="Timeframe" name="timeframe" className={inputClass} onChange={handleInputChange} required />
            </div>

            <div className="mt-6">
                <div className="grid md:grid-cols-3 gap-5 align-end">
                    <div className='col-span-1 md:ml-[26px] flex flex-col md:mt-4'>
                        <div>
                            <div className="flex items-center mb-4">
                                <input id="question1" name="question1" type="checkbox" className="w-4 h-4" onChange={handleInputChange} />
                                <label htmlFor="question1" className="ml-2.5 text-sm font-medium text-gray-300">Will I be working on another person's code?</label>
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center">
                                <input id="question2" name="question2" type="checkbox" className="w-4 h-4" onChange={handleInputChange} />
                                <label htmlFor="question2" className="ml-2.5 text-sm font-medium text-gray-300">Will I be hooking with someone else's plugin?</label>
                            </div>
                        </div>
                    </div>
                    <textarea name="additional_information" placeholder="Additional Information" className={`${inputClass} rounded-xl col-span-2`} rows="3" onChange={handleInputChange}></textarea>
                </div>
            </div>

            <p className="mt-4">
                {message}
            </p>

            <button className={`py-3 px-6 rounded font-semibold bg-primary hover:bg-sky-600 transition mt-4 ${success ? 'hidden' : ''}`} type='submit'>Submit</button>
        </form>
    </div>
  )
}
