import React from 'react';

import { TypeAnimation } from 'react-type-animation';
import { scrollToSection } from '../utils/scrollToSection';

import { GoChevronDown } from 'react-icons/go';

export default function Hero() {
  return (
    <div className="container mt-44 flex flex-col items-center">
      <div className="text-center">
        <h1 className="text-5xl font-bold">Hey 👋, <TypeAnimation sequence={['I\'m Illusion!']} speed={200} /></h1>
        <h2 className="text-3xl max-w-[35rem] mt-4">
          Welcome to my Portfolio page
        </h2>

        <div className="flex justify-center mt-8">
          <a
          href="#about" 
          onClick={(e) => {
              e.preventDefault();
              scrollToSection('#about');
          }}
          className="flex items-center space-x-2 font-semibold transition bg-primary rounded px-8 py-3 hover:scale-105"
          >
            <span>Scroll down</span>
            <GoChevronDown className="text-2xl" />
          </a>
        </div>
      </div>
    </div>
  );
}
